import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import * as XLSX from "xlsx";
import ApiConfig from "../Api/ApiConfig";
import Buttons from "./buttons";

const numericComparator = (valueA, valueB) => {
  const numA = parseFloat(valueA) || 0;
  const numB = parseFloat(valueB) || 0;
  return numA - numB;
};
const columns = [
  {
    headerName: "Live Market",
    children: [
      {
        field: "symbol",
        headerName: "Symbol",
        width: 150,
        pinned: "left",
        comparator: numericComparator,
        cellDataType: "numeric",
      },
      {
        field: "chart",
        headerName: "Trading View Chart",
        width: 150,
        pinned: "left",
        comparator: numericComparator,
        cellDataType: "numeric",
        cellRenderer: (params) => (
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            View Chart
          </a>
        ),
      },
      {
        field: "pChange",
        headerName: "%Chng",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        pinned: "left",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.ratioAvgValue",
        headerName: "Today Value/ Avg. Value",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        pinned: "left",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.ratioMaxValue",
        headerName: "Today Value/ Max Value",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        pinned: "left",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "open",
        headerName: "Open",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "dayHigh",
        headerName: "High",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "dayLow",
        headerName: "Low",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "previousClose",
        headerName: "Prev. Close",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "lastPrice",
        headerName: "LTP",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "change",
        headerName: "Chng",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "totalTradedVolume",
        headerName: "Volume (shares)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "volumeInLakhs",
        headerName: "Volume (Lakhs)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "yearHigh",
        headerName: "52W H",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "yearLow",
        headerName: "52W L",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "perChange30d",
        headerName: "30 D %CHNG",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "perChange365d",
        headerName: "365 D %CHNG",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "pYearHigh",
        headerName: "(LTP-52 WH)*100/52 WH",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
    ],
  },
  {
    headerName: "Historical Data",
    children: [
      {
        field: "historicalData.maxVolumes",
        headerName: "Maximum of All Volumes (Lakhs)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.avgVolumes",
        headerName: "Average of All Volumes (Lakhs)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.maxValue",
        headerName: "Maximum of All Values (Crores)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.avgValue",
        headerName: "Average of All Values (Crores)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.tradedVolume",
        headerName: "Today Volume (Lakhs)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.ratioAvgVolumes",
        headerName: "Today Volume/ Avg. Volume",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.ratioMaxVolumes",
        headerName: "Today Volume/ Max Volume",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "totalTradedValue",
        headerName: "Value (₹ crores)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
    ],
  },
  {
    headerName: "Trade Information",
    children: [
      {
        field: "tradeInfo.orderBookBuyQty",
        headerName: "Order Book Buy Quantity",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "tradeInfo.ordrtBookSellQty",
        headerName: "Order Book Sell Quantity",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "tradeInfo.sellVsBuy",
        headerName: "Sell/Buy Ratio",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "tradeInfo.buyVsSell",
        headerName: "Buy/Sell Ratio",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
    ],
  },
  {
    headerName: "Calculation",
    children: [
      {
        field: "pHigh",
        headerName: "(LTP - High)*100/High",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "pLow",
        headerName: "(LTP - Low)*100/Low",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "gapUpVsGapDown",
        headerName: "Gap Up/Gap Down",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "pOpen",
        headerName: "(LTP - Open)*100/Open",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "historicalData.tradedValue",
        headerName: "Today Value (Crores)",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => (params.value || 0).toFixed(2),
      },
      {
        field: "sellValueMinusBuyValue",
        headerName: "Sell Value - Buy Value",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "buyValueMinusSellValue",
        headerName: "Buy Value - Sell Value",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "sellValue",
        headerName: "Sell Value",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "buyValue",
        headerName: "Buy Value",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "sellValueVsBuyValue",
        headerName: "Sell Value / Buy Value",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
      {
        field: "buyValueVsSellValue",
        headerName: "Buy Value / Sell Value",
        width: 150,
        comparator: numericComparator,
        cellDataType: "numeric",
        valueFormatter: (params) => Number(params.value || 0).toFixed(2),
      },
    ],
  },
  {
    headerName: "Time",
    children: [
      {
        field: "updatedAt",
        headerName: "Updated At Time",
        width: 170,
        cellDataType: "String",
      },
    ],
  },
  {
    headerName: "Reset",
    children: [
      {
        field: "reset",
        headerName: "Reset",
        width: 80,
        cellRenderer: (params) => (
          <button
            style={{ color: "blue", textDecoration: "underline" }}
            onClick={async () => {
              await axios.get(`${ApiConfig.resetData}${params.data.symbol}`);
              console.log("Reset button clicked", params.data.symbol);
              window.alert("Data Reset Successfully");
            }}
          >
            Reset
          </button>
        ),
      },
    ],
  },
];

const NiftyTotal = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ApiConfig.niftyTotal);

        const finalResponse = response?.data?.data;
        // console.log(finalResponse);
        finalResponse.map((item) => {
          // Ensure historicalData exists
          if (!item.historicalData) {
            item.historicalData = {
              maxVolumes: 0,
              avgVolumes: 0,
              maxValue: 0,
              avgValue: 0,
              tradedVolume: 0,
              tradedValue: 0,
              ratioMaxVolumes: 0,
              ratioAvgVolumes: 0,
              ratioMaxValue: 0,
              ratioAvgValue: 0,
            };
          }

          // Safely update historicalData fields
          item.historicalData.tradedVolume = item.volumeInLakhs || 0;
          item.historicalData.tradedValue = item.totalTradedValue || 0;
          item.historicalData.ratioAvgVolumes =
            item.historicalData.avgVolumes !== 0
              ? (item.volumeInLakhs / item.historicalData.avgVolumes).toFixed(2)
              : 0.0;
          item.historicalData.ratioMaxVolumes =
            item.historicalData.maxVolumes !== 0
              ? (item.volumeInLakhs / item.historicalData.maxVolumes).toFixed(2)
              : 0.0;
          item.historicalData.ratioAvgValue =
            item.historicalData.avgValue !== 0
              ? (item.totalTradedValue / item.historicalData.avgValue).toFixed(
                  2
                )
              : 0.0;
          item.historicalData.ratioMaxValue =
            item.historicalData.maxValue !== 0
              ? (item.totalTradedValue / item.historicalData.maxValue).toFixed(
                  2
                )
              : 0.0;
          // Convert updatedAt from UTC to IST
          if (item.updatedAt) {
            const utcDate = new Date(item.updatedAt);
            const istDate = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000);
            item.updatedAt = istDate
              .toISOString()
              .replace("T", " ")
              .substring(0, 19);
          }
        });

        const totalbuyValue = finalResponse.reduce(
          (sum, row) => sum + (Number(row.buyValue) || 0),
          0
        );
        const totalsellValue = finalResponse.reduce(
          (sum, row) => sum + (Number(row.sellValue) || 0),
          0
        );
        const totalSellValueMinusBuyValue = finalResponse.reduce(
          (sum, row) => sum + (Number(row.sellValueMinusBuyValue) || 0),
          0
        );
        const totalBuyValueMinusSellValue = finalResponse.reduce(
          (sum, row) => sum + (Number(row.buyValueMinusSellValue) || 0),
          0
        );
        const totalbuyValueVsSellValue = finalResponse.reduce(
          (sum, row) => sum + (Number(row.buyValueVsSellValue) || 0),
          0
        );
        const totalsellValueVsBuyValue = finalResponse.reduce(
          (sum, row) => sum + (Number(row.sellValueVsBuyValue) || 0),
          0
        );
        const totalSellVsBuyRatio = finalResponse.reduce(
          (sum, row) => sum + (Number(row.tradeInfo?.sellVsBuy) || 0),
          0
        );
        const totalBuyVsSellRatio = finalResponse.reduce(
          (sum, row) => sum + (Number(row.tradeInfo?.buyVsSell) || 0),
          0
        );

        // Add a totals row to the data
        finalResponse.push({
          symbol: "Total",
          priority: 2,
          buyValue: totalbuyValue.toFixed(2),
          sellValue: totalsellValue.toFixed(2),
          buyValueVsSellValue: totalbuyValueVsSellValue.toFixed(2),
          sellValueVsBuyValue: totalsellValueVsBuyValue.toFixed(2),
          sellValueMinusBuyValue: totalSellValueMinusBuyValue.toFixed(2),
          buyValueMinusSellValue: totalBuyValueMinusSellValue.toFixed(2),
          tradeInfo: {
            sellVsBuy: totalSellVsBuyRatio,
            buyVsSell: totalBuyVsSellRatio,
          },
        });
        // Sort the data first by keyPriority, then by historicalData.ratioMaxValue
        finalResponse.sort((a, b) => {
          if (a.priority !== b.priority) {
            return b.priority - a.priority;
          }
          return (
            b.historicalData.ratioMaxValue - a.historicalData.ratioMaxValue
          );
        });
        setRowData(finalResponse);
        setLoading(false);
      } catch {
        console.log("Error fetching data");
      }
    };
    fetchData();
  }, []);

  const handleDownloadExcel = () => {
    // Define the correct main headers (group headers) for each set of columns
    const repeat = (item, times) => Array(times).fill(item);

    const mainHeaders = [
      ...repeat("Live Market", 15),
      // 'Calculation',
      ...repeat("Historical Data", 8),
      ...repeat("Trade Information", 4),
      ...repeat("Calculation", 12),
      ...repeat("Time", 1),
    ];

    // Subheaders correspond to each column in your data grid
    const subHeaders = columns.flatMap((col) =>
      col.children.map((head) => head.headerName)
    );

    // Flatten all rows to prepare data for Excel
    const dataRows = rowData.map((row) => [
      row.symbol,
      row.chart,
      row.pChange || 0,
      row.historicalData?.ratioAvgValue || 0,
      row.historicalData?.ratioMaxValue || 0,
      row.open || 0,
      row.dayHigh || 0,
      row.dayLow || 0,
      row.previousClose || 0,
      row.lastPrice || 0,
      (row.change || 0).toFixed(2),
      row.totalTradedVolume || 0,
      row.volumeInLakhs || 0,
      row.yearHigh || 0,
      row.yearLow || 0,
      row.perChange30d || 0,
      row.perChange365d || 0,
      row.pYearHigh || 0,
      // row.liveMarketCalculation,
      row.historicalData?.maxVolumes || 0,
      row.historicalData?.avgVolumes || 0,
      row.historicalData?.maxValue || 0,
      row.historicalData?.avgValue || 0,
      row.historicalData?.tradedVolume || 0,
      row.historicalData?.ratioAvgVolumes || 0,
      row.historicalData?.ratioMaxVolumes || 0,
      row.totalTradedValue || 0,
      row.tradeInfo?.orderBookBuyQty || 0,
      row.tradeInfo?.ordrtBookSellQty || 0,
      row.tradeInfo?.sellVsBuy || 0,
      row.tradeInfo?.buyVsSell || 0,
      row.pHigh || 0,
      row.pLow || 0,
      row.gapUpVsGapDown || 0,
      row.pOpen || 0,
      row.historicalData?.tradedValue || 0,
      row.sellValueMinusBuyValue || 0,
      row.buyValueMinusSellValue || 0,
      row.sellValue || 0,
      row.buyValue || 0,
      row.sellValueVsBuyValue || 0,
      row.buyValueVsSellValue || 0,
      row.updatedAt,
    ]);

    // Prepare the worksheet data with grouped headers and subheaders
    const worksheetData = [
      mainHeaders, // First row: main headers (groups)
      subHeaders, // Second row: subheaders (column names)
      ...dataRows,
    ];

    // Create worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    worksheet["!cols"] = Array(worksheetData[1].length).fill({ wch: 20 });

    // Define the correct merge ranges for group headers (merging horizontally across subheaders)
    const mergeRanges = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 14 } }, // 'Live Market' spanning 15 columns
      // { s: { r: 0, c: 15 }, e: { r: 0, c: 15 } },  // 'Live Market' spanning 15 columns
      { s: { r: 0, c: 15 }, e: { r: 0, c: 22 } }, // 'Historical Information' spanning 10 columns
      { s: { r: 0, c: 23 }, e: { r: 0, c: 26 } }, // 'Trade Information' spanning 4 columns
      { s: { r: 0, c: 27 }, e: { r: 0, c: 38 } },
      { s: { r: 0, c: 39 }, e: { r: 0, c: 39 } },
    ];

    // Apply merges to worksheet
    if (!worksheet["!merges"]) worksheet["!merges"] = [];
    worksheet["!merges"].push(...mergeRanges);

    // Create workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Market Data");

    // Export the workbook to Excel
    XLSX.writeFile(workbook, "Nifty_Total_Market_Data.xlsx");
  };
  return (
    <>
      <div className="ag-theme-alpine relative h-[60vh]">
        <h1 className="text-center text-4xl mb-8 font-bold tracking-widest">
          Nifty Total Market
        </h1>
        <div className="absolute right-0 z-10 top-0">
          <Buttons handleDownloadExcel={handleDownloadExcel} />
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={columns}
          loadingOverlayComponentParams={{ loadingMessage: "Loading data..." }}
          loading={loading ? true : false}
          defaultColDef={{
            resizable: true,
            sortable: true,
          }}
          alwaysShowHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
          pinnedTopRowData={columns.headerName} // For sticky headers
          pinnedBottomRowData={[]} // For sticky footers
          animateRows={true}
          onGridReady={(params) => {
            params.api.sizeColumnsToFit(); // Auto fit columns on grid ready
          }}
        />
      </div>
    </>
  );
};

export default NiftyTotal;
